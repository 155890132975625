import React, {useEffect, useState} from "react";
import {PowerBIEmbed} from "powerbi-client-react";
import {models} from "powerbi-client";
import "./LeaseTeamLeadTablet.scss";
import {MicrosoftToken} from "../Services/DashboardService";
import {PowerBiEnum} from "../Enum/PowerBi.Enum";
import {GetURLSearchParamsMethod} from "../Services/GetURLSearchParamsMethod";

//Lease Team lead Tablet (Teamid)
export const LeaseTeamLeadTablet = () => {
    const [report, setReport] = useState();
    const [render, setRender] = useState(false);

    const Userid = GetURLSearchParamsMethod("id");

    const Microsoft = async (isExpired) => {
        const res = await MicrosoftToken(isExpired);
        if (res.data) {
            setReport(res.data || null);
            setRender(true);
        } else {
            setReport(null);
        }
    };
    useEffect(() => {
        Microsoft(false);
    }, []);

    const Filter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "Team Users BusinessGroups",
            column: "TeamsId",
        },
        operator: "In",
        values: [+Userid],
        filterType: models.FilterType.BasicFilter,
        requireSingleSelection: true,
    };


    return (
        <div>
            <div>
                {render && (
                    <div className='web'>
                        <PowerBIEmbed
                            embedConfig={{
                                type: "report",
                                id: PowerBiEnum.LeaseTabletAgent.reportid,
                                embedUrl:
                                PowerBiEnum.LeaseTabletAgent.url,
                                accessToken: report,
                                filters: [Filter],
                                settings: {
                                    customLayout: {
                                        displayOption: models.DisplayOption.FitToWidth,
                                    },
                                    filterPaneEnabled: false,
                                    navContentPaneEnabled: false,
                                    panes: {
                                        filters: {
                                            expanded: false,
                                            visible: false,
                                        },
                                    },
                                    background: models.BackgroundType.Transparent,
                                },
                            }}
                            eventHandlers={
                                new Map([
                                    [
                                        "loaded",
                                        () => {
                                            console.log("Report loaded");
                                        },
                                    ],
                                    [
                                        "rendered",
                                        () => {
                                            console.log("Report rendered");
                                        },
                                    ],
                                    [
                                        "error",
                                        (event) => {
                                            console.log("Error");
                                            const error = event.detail;

                                            if (error.detailedMessage === 'Get report failed') {
                                                Microsoft(true);
                                                setRender(false);

                                            } else {
                                                console.log(error.message);
                                            }
                                        },
                                    ],
                                ])
                            }
                            cssClassName={"report-style-class"}
                            getEmbeddedComponent={(embeddedReport) => {
                                window.report = embeddedReport;
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
