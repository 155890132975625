import {SalesTeamLeadTablet} from './SalesTeamLeadTablet';
import {SalesAdminMobile} from './SalesAdminMobile';
import {SalesTeamLeadWeb} from './SalesTeamLeadWeb';
import {GetURLSearchParamsMethod} from './Services/GetURLSearchParamsMethod';
import {SalesAgentWeb} from './SalesAgentWeb';
import {SalesAgentMobile} from './SalesAgentMobile';
import {SalesAgentTablet} from './SalesAgentTablet';
import {SalesAdminWeb} from "./SalesAdminWeb";
import {SalesAdminTablet} from "./SalesAdminTablet";
import {LeaseAgentWeb} from "./LeaseAgentWeb";
import {LeaseAgentMobile} from "./LeaseAgentMobile";
import {LeaseAgentTablet} from "./LeaseAgentTablet";
import {LeaseAdminWeb} from "./LeaseAdminWeb";
import {SalesTeamLeadMobile} from "./SalesTeamLeadMobile";
import {LeaseAdminMobile} from "./LeaseAdminMobile";
import {LeaseAdminTablet} from "./LeaseAdminTablet";
import {LeaseTeamLeadWeb} from "./LeaseTeamLeadWeb";
import {LeaseTeamLeadMobile} from "./LeaseTeamLeadMobile";
import {LeaseTeamLeadTablet} from "./LeaseTeamLeadTablet";
import {CallCenterAdminWeb} from "./CallCenterAdminWeb";
import {CallCenterAdminMobile} from "./CallCenterAdminMobile";
import {CallCenterAdminTablet} from "./CallCenterAdminTablet";
import {MarketingAdminWeb} from "./MarketingAdminWeb";
import {MarketingAdminMobile} from "./MarketingAdminMobile";
import {MarketingAdminTablet} from "./MarketingAdminTablet";
import {AccountAdminMobile} from "./AccountAdminMobile";
import {AccountAdminTablet} from "./AccountAdminTablet";
import {AccountAdminWeb} from "./AccountAdminWeb";
import {SalesListingAdminMobile} from "./SalesListingAdminMobile";
import {SalesListingAdminTablet} from "./SalesListingAdminTablet";
import {SalesListingAdminWeb} from "./SalesListingAdminWeb";
import {SalesListingAgentMobile} from "./SalesListingAgentMobile";
import {SalesListingAgentTablet} from "./SalesListingAgentTablet";
import {SalesListingAgentWeb} from "./SalesListingAgentWeb";
import {SalesListingTeamLeadMobile} from "./SalesListingTeamLeadMobile";
import {SalesListingTeamLeadTablet} from "./SalesListingTeamLeadTablet";
import {SalesListingTeamLeadWeb} from "./SalesListingTeamLeadWeb";
import {LeaseListingAdminMobile} from "./LeaseListingAdminMobile";
import {LeaseListingAdminTablet} from "./LeaseListingAdminTablet";
import {LeaseListingAdminWeb} from "./LeaseListingAdminWeb";
import {LeaseListingTeamLeadWeb} from "./LeaseListingTeamLeadWeb";
import {LeaseListingTeamLeadMobile} from "./LeaseListingTeamLeadMobile";
import {LeaseListingTeamLeadTablet} from "./LeaseListingTeamLeadTablet";
import {LeaseListingAgentMobile} from "./LeaseListingAgentMobile";
import {LeaseListingAgentTablet} from "./LeaseListingAgentTablet";
import {LeaseListingAgentWeb} from "./LeaseListingAgentWeb";
import { CeoAdminMobile } from './CeoAdminMobile';

//For Admin:
//?salesAdmin=
//?leaseAdmin=
//?salesListingViewAdmin=
//?leaseListingViewAdmin=
//?ccenterAdmin=
//?marketingAdmin=
//?accountAdmin=
//?ceoAdmin=

//For SalesTeamLead And LeaseTeamLead Params:
//?id=&view=     sales-team-lead
//?id=&leaseTeamview=     lease-team-lead
//?id=&salesListingViewTeamlead=   salesListing-team-lead
//?id=&leaseListingViewTeamlead=   leaseListing-team-lead

//For SalesAgent And LeaseAgent Params:
//?userid=&agentview=     sales-Agent
//?userid=&leaseAgentview=   lease-Agent
//?userid=&salesListingViewAgent=   salesListing-Agent
//?userid=&leaseListingViewAgent=   leaseListing-Agent



function App() {
    //sales-team-lead
    const View = GetURLSearchParamsMethod('view')
    //sales-Agent
    const AgentView = GetURLSearchParamsMethod('agentview')
    //Sales-Admin
    const SalesAdmin = GetURLSearchParamsMethod('salesAdmin')
    //lease-Admin
    const LeaseAdmin = GetURLSearchParamsMethod('leaseAdmin')
    // lease-Agent
    const LeaseAgentView = GetURLSearchParamsMethod('leaseAgentview')
    //lease-team-lead
    const LeaseTeamView = GetURLSearchParamsMethod('leaseTeamview')
    //Call Center Admin
    const CCenterAdmin = GetURLSearchParamsMethod('ccenterAdmin')
    //Marketing Admin
    const MarketingAdmin = GetURLSearchParamsMethod('marketingAdmin')
     //Account Admin
    const AccountAdmin = GetURLSearchParamsMethod('accountAdmin')
    //Sales Listing  Admin
    const SalesListingViewAdmin = GetURLSearchParamsMethod('salesListingViewAdmin')
    //Sales Listing  Agent
    const SalesListingViewAgent = GetURLSearchParamsMethod('salesListingViewAgent')
    //Sales Listing  Teamlead
    const SalesListingViewTeamlead = GetURLSearchParamsMethod('salesListingViewTeamlead')
    //Lease Listing  Admin
    const LeaseListingViewAdmin = GetURLSearchParamsMethod('leaseListingViewAdmin')
    //Lease Listing  Teamlead
    const LeaseListingViewTeamlead = GetURLSearchParamsMethod('leaseListingViewTeamlead')
    //Lease Listing  Agent
    const LeaseListingViewAgent = GetURLSearchParamsMethod('leaseListingViewAgent')
    //Ceo admin
    const CeoAdmin = GetURLSearchParamsMethod('ceoAdmin')


    return (
        <div className="App">
            {
                ((CeoAdmin === 'mobile') && <CeoAdminMobile/>)
            }


            {((SalesAdmin === 'web') && <SalesAdminWeb/>)
                || ((SalesAdmin === 'mobile') && <SalesAdminMobile/>)
                || ((SalesAdmin === 'tablet') && <SalesAdminTablet/>)}

            {((LeaseAdmin === 'web') && <LeaseAdminWeb/>)
                || ((LeaseAdmin === 'mobile') && <LeaseAdminMobile/>)
                || ((LeaseAdmin === 'tablet') && <LeaseAdminTablet/>)}

            {((CCenterAdmin === 'web') && <CallCenterAdminWeb/>)
                || ((CCenterAdmin === 'mobile') && <CallCenterAdminMobile/>)
                || ((CCenterAdmin === 'tablet') && <CallCenterAdminTablet/>)}

            {((MarketingAdmin === 'web') && <MarketingAdminWeb/>)
                || ((MarketingAdmin === 'mobile') && <MarketingAdminMobile/>)
                || ((MarketingAdmin === 'tablet') && <MarketingAdminTablet/>)}

            {((AccountAdmin === 'web') && <AccountAdminWeb/>)
                || ((AccountAdmin === 'mobile') && <AccountAdminMobile/>)
                || ((AccountAdmin === 'tablet') && <AccountAdminTablet/>)}

            {((SalesListingViewAdmin === 'mobile') && <SalesListingAdminMobile/>)
                ||((SalesListingViewAdmin === 'tablet') && <SalesListingAdminTablet/>)
                ||((SalesListingViewAdmin === 'web') && <SalesListingAdminWeb/>)
                }
            {((LeaseListingViewAdmin === 'mobile') && <LeaseListingAdminMobile/>)
                ||((LeaseListingViewAdmin === 'tablet') && <LeaseListingAdminTablet/>)
                ||((LeaseListingViewAdmin === 'web') && <LeaseListingAdminWeb/>)}

            {(((View === 'mobile') && <SalesTeamLeadMobile/>)
                    || ((View === 'tablet') && <SalesTeamLeadTablet/>))
                || ((View === 'web') && <SalesTeamLeadWeb/>)}

            {((AgentView === 'web') && <SalesAgentWeb/>)
                || ((AgentView === 'mobile') && <SalesAgentMobile/>)
                || ((AgentView === 'tablet') && <SalesAgentTablet/>)}

            {((LeaseTeamView === 'web') && <LeaseTeamLeadWeb/>)
                || ((LeaseTeamView === 'mobile') && <LeaseTeamLeadMobile/>)
                || ((LeaseTeamView === 'tablet') && <LeaseTeamLeadTablet/>)}

            {((LeaseAgentView === 'web') && <LeaseAgentWeb/>)
                || ((LeaseAgentView === 'mobile') && <LeaseAgentMobile/>)
                || ((LeaseAgentView === 'tablet') && <LeaseAgentTablet/>)}

            {((SalesListingViewTeamlead === 'web') && <SalesListingTeamLeadWeb/>)
                || ((SalesListingViewTeamlead === 'mobile') && <SalesListingTeamLeadMobile/>)
                || ((SalesListingViewTeamlead === 'tablet') && <SalesListingTeamLeadTablet/>)}

            {((SalesListingViewAgent === 'web') && <SalesListingAgentWeb/>)
                || ((SalesListingViewAgent === 'mobile') && <SalesListingAgentMobile/>)
                || ((SalesListingViewAgent === 'tablet') && <SalesListingAgentTablet/>)}


            {((LeaseListingViewTeamlead === 'web') && <LeaseListingTeamLeadWeb/>)
                || ((LeaseListingViewTeamlead === 'mobile') && <LeaseListingTeamLeadMobile/>)
                || ((LeaseListingViewTeamlead === 'tablet') && <LeaseListingTeamLeadTablet/>)}

            {((LeaseListingViewAgent === 'web') && <LeaseListingAgentWeb/>)
                || ((LeaseListingViewAgent === 'mobile') && <LeaseListingAgentMobile/>)
                || ((LeaseListingViewAgent === 'tablet') && <LeaseListingAgentTablet/>)}

        </div>
    );
}
export default App;

