import {HttpServices} from "./HttpMethod.Helper";

const MicrosoftToken = async (isExpired) => {
  const result = await HttpServices.get(`https://apigateway.dev.psi-crm.com/Identity/Account/GenerateMicrosoftToken?isExpired=${isExpired}`)
      .then((data) => data)
      .catch((error) => error.response);
  return result;
};


const TokenReport = async (reportid, groupid) => {
  try {
    const result = await HttpServices.post(
      `https://apigateway.dev.psi-crm.com/Identity/Account/GetPowerPiToken?reports=${reportid}&groups=${groupid}`
    );
    return result;

  } catch (e) {
    return undefined;
  }
};

const RefreshTokenPbi = async (body) => {
  const result = await HttpServices.post(
    `https://apigateway.dev.psi-crm.com/Identity/Account/RefreshTokenPowerBi`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export { TokenReport, RefreshTokenPbi,MicrosoftToken };
