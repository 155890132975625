export const PowerBiEnum = {
    Tablet: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "a07249e9-c6f0-42de-b038-061e1b141ed5",
        url: "https://app.powerbi.com/reportEmbed?reportId=a07249e9-c6f0-42de-b038-061e1b141ed5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",

    },
    Mobile: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "652e17d6-8502-4b07-a901-a7b4aabebea6",
        url: "https://app.powerbi.com/reportEmbed?reportId=652e17d6-8502-4b07-a901-a7b4aabebea6&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",

    },
    Web: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "8c81de09-4fc8-4d21-bb77-4728702afb93",
        url: "https://app.powerbi.com/reportEmbed?reportId=8c81de09-4fc8-4d21-bb77-4728702afb93&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",

    },

    leasingWeb: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "2c42857e-2ed6-4e51-8bbd-e5670ec2df7d",
        url: "https://app.powerbi.com/reportEmbed?reportId=2c42857e-2ed6-4e51-8bbd-e5670ec2df7d&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },
    leasingAdminMobile: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "731b444b-f5d1-4ed6-a934-01239ddace63",
        url: "https://app.powerbi.com/reportEmbed?reportId=731b444b-f5d1-4ed6-a934-01239ddace63&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d"
    },
    LeaseAgent: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "9bf59c54-2096-41db-af3e-b1a2a2db70fc",
        url: "https://app.powerbi.com/reportEmbed?reportId=9bf59c54-2096-41db-af3e-b1a2a2db70fc&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },
    LeaseMobileAgent: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "53b22b25-7920-4da2-8504-6a8210b02e80",
        url: "https://app.powerbi.com/reportEmbed?reportId=53b22b25-7920-4da2-8504-6a8210b02e80&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },
    LeaseTabletAgent: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "e027b42f-8c0e-46a3-9093-3cdb5dd790ea",
        url: "https://app.powerbi.com/reportEmbed?reportId=e027b42f-8c0e-46a3-9093-3cdb5dd790ea&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },
    WebAgent: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "090a8443-9fbf-40fc-b03b-d09fcd1fa03a",
        url: "https://app.powerbi.com/reportEmbed?reportId=090a8443-9fbf-40fc-b03b-d09fcd1fa03a&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },
    MobileAgent: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "52442069-e2fa-4f53-b31d-776ede53c05b",
        url: "https://app.powerbi.com/reportEmbed?reportId=52442069-e2fa-4f53-b31d-776ede53c05b&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
    },
    TabletAgent: {
        groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid: "82d48ed7-1032-49e7-9b50-b135854114c0",
        url: "https://app.powerbi.com/reportEmbed?reportId=82d48ed7-1032-49e7-9b50-b135854114c0&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
    },
    CallCenterWeb: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "0667ec04-e216-4397-a91a-7e910126f0c2",
        url :"https://app.powerbi.com/reportEmbed?reportId=0667ec04-e216-4397-a91a-7e910126f0c2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },
    CallCenterMobile: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "344bd924-1650-45e1-8ddc-a9067bbc43df",
        url:"https://app.powerbi.com/reportEmbed?reportId=344bd924-1650-45e1-8ddc-a9067bbc43df&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },
    CallCenterTablet: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "635e8f5a-30bd-4328-9370-75d70ce7abcd",
        url:"https://app.powerbi.com/reportEmbed?reportId=635e8f5a-30bd-4328-9370-75d70ce7abcd&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"

    },

    marketing: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "8bfa5689-bc70-4027-a57d-98100119ab29",
        url:"https://app.powerbi.com/reportEmbed?reportId=8bfa5689-bc70-4027-a57d-98100119ab29&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },
    marketingMobile: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "52122ce3-e9b6-43b8-a5b3-2d3f033275bc",
        url:"https://app.powerbi.com/reportEmbed?reportId=52122ce3-e9b6-43b8-a5b3-2d3f033275bc&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },
    marketingTablet: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "c8d9ee92-f1a3-424b-886b-979c50d52d94",
        url:"https://app.powerbi.com/reportEmbed?reportId=c8d9ee92-f1a3-424b-886b-979c50d52d94&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },

    accountWeb: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "74f57fe0-0b1c-4774-9707-2c91187c0efb",
        url:"https://app.powerbi.com/reportEmbed?reportId=74f57fe0-0b1c-4774-9707-2c91187c0efb&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },

    accountMobile: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "d5d90fe6-41de-4d05-808f-58342d39161f",
        url:"https://app.powerbi.com/reportEmbed?reportId=d5d90fe6-41de-4d05-808f-58342d39161f&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },

    accountTablet: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "0d30ee3b-1389-4551-91a7-6d643d372383",
        url:"https://app.powerbi.com/reportEmbed?reportId=0d30ee3b-1389-4551-91a7-6d643d372383&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19"
    },
    salesListingAdminMobile: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "229869a7-63cb-44e1-8183-6150cd1c4df6",
        url:"https://app.powerbi.com/reportEmbed?reportId=229869a7-63cb-44e1-8183-6150cd1c4df6&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d"
    },
    salesListingAdminTablet: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "a9266a4e-7d59-4971-98be-6b517818db1a",
        url:"https://app.powerbi.com/reportEmbed?reportId=a9266a4e-7d59-4971-98be-6b517818db1a&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d"
    },
    salesListingAdminWeb: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "62302fde-3c11-450e-b25d-308f7e1838c8",
        url:"https://app.powerbi.com/reportEmbed?reportId=62302fde-3c11-450e-b25d-308f7e1838c8&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d"
    },
    leaseListingAdminMobile: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "f5d098db-5f89-4e2e-8251-b398d2a70bf4",
        url:"https://app.powerbi.com/reportEmbed?reportId=f5d098db-5f89-4e2e-8251-b398d2a70bf4&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d"
    },

    leaseListingAdminTablet: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "ab78244b-4ffe-473e-ac9e-263ebb652ed1",
        url:"https://app.powerbi.com/reportEmbed?reportId=ab78244b-4ffe-473e-ac9e-263ebb652ed1&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d"
    },

    leaseListingAdminWeb: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "d310adc4-99e0-4d28-af32-d3d854624658",
        url:"https://app.powerbi.com/reportEmbed?reportId=d310adc4-99e0-4d28-af32-d3d854624658&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d"
    },
    ceoAdminmobile: {
        groupid:  "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
        reportid:  "3be78214-7ef8-4d3d-92a1-f5592b047661",
        url:"https://app.powerbi.com/reportEmbed?reportId=3be78214-7ef8-4d3d-92a1-f5592b047661&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d"
    },

};
